body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

html {
  height:100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.event-card {
  /*border: 1px solid #000;*/
  transition: transform 0.3s, box-shadow 0.3s;
  flex-direction: row;

}

.event-card:hover, .event-card:active {
  transform: scale(1.002);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}


@media (min-width: 200px) {
  .custom-card {
    flex-direction: row;
  }

  .card-img-left {
    width: auto;
    max-width: 150px; /* Adjust this value based on your image size */
    height: auto;
  }
}

.login-card {
  margin-top: 100px;
  padding: 20px;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0.175);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
}


.gradient-background {
  background: linear-gradient(90deg,#E0A75E,#F9D689,#F5E7B2);
  background-size: 240% 240%;
  animation: gradient-animation 1s ease infinite;

}

@keyframes gradient-animation {
  0% {
    background-position: 0% 20%;
  }
  50% {
    background-position: 50% 20%;
  }
  100% {
    background-position: 0% 20%;
  }
}


.gradient-background2 {
  background: linear-gradient(180deg,#E0A75E,#F9D689,#ffffff);
  background-size: 240% 500%;

  animation: gradient-animation 1s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 80% 100%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 100% 100%;
  }
}

.bidirectional-gradient {
  height: 100%; /* Ensure the div takes the full height */
  background:
    linear-gradient(to top, #ffffff 0%, rgba(255, 255, 255, 0) 80%);
  background-size: 100% 100%, 100% 20%;
  background-position: 0 0, 0 80%;
  background-repeat: no-repeat;
}


.mapboxgl-popup-content {
  background: none;
  width:fit-content;
  box-shadow: none;
}
